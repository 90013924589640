import * as React from "react"
import { useEffect } from "react"
import Subheading from "../../story/subheading"
import Wrapper from "../../layout/wrapper"

import SvgLeft from "../../../images/learn-countdown-left.svg"
import SvgRight from "../../../images/learn-countdown-right.svg"

import DateCountdown from 'react-date-countdown-timer';
import "./countdown.css"


const Countdown = ({ countdown }) => {

const isBrowser = typeof window !== "undefined"

useEffect(() => {
    import (`./countdown.css`);
}, [isBrowser]);

const CountdownCounter = ({}) => {
    return isBrowser ? (
        <DateCountdown
        mostSignificantFigure="month" 
        numberOfFigures="3"
        dateTo='January 21, 2023 09:00:00 GMT+00:00' 
        callback={()=>alert('Hello')}  
        />
    ) : (
        <span></span>
    )
}
    return (
        <Wrapper>
            <div className="
                w-full 
                lg:my-24
                lg:py-48 
                relative"
                >
                <img src={SvgLeft} alt="" 
                    className="w-[176px] h-[703.9px] absolute left-0 top-0 hidden lg:block"
                />
                <img src={SvgRight} alt="" 
                    className="w-[231.43px] h-[689px] absolute right-0 top-0 hidden lg:block"
                />
                <div className="
                    flex flex-col
                    gap-8 lg:gap-16
                    items-center 
                    justify-center"
                >
                    <Subheading text="Course will start in:" customClass={"text-center text-[28px] lg:text-[60px]"}/>
                    <div className="counter">
                        <CountdownCounter/>
                    </div>   
                </div>
            </div>
        </Wrapper>
    )
}

export default Countdown