import * as React from "react"
import { graphql } from "gatsby"

// Custom components
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Story from "../components/story/story"
import Wrapper, { WrapperDesktopOnly } from "../components/layout/wrapper" 
import AboutBox from "../components/curriculum/learn/about-box"
import MarkdownContent from "../components/team/mdxhtmlConverter"
import VideoSection from "../components/curriculum/learn/video-section"
import Features from "../components/curriculum/learn/features"
import Trainer from "../components/curriculum/learn/trainer"
import InfoCards from "../components/curriculum/learn/info-cards-ideas"
import Button from "../components/story/button"
import Heading from "../components/story/heading"
import Sessions from "../components/curriculum/learn/sessions"
import Included from "../components/curriculum/learn/included"

// Static files
import Line from "../images/line-curriculum.svg"
import TeamLine from "../images/line-team.svg"
import Subheading from "../components/story/subheading"
import Countdown from "../components/curriculum/learn/countdown"
import DescBg from "../images/learn-bg-desc.svg"

const LineData = (
  [Line, 'top-[800px]', 'left-0', '', '']
)
const TeamLineData = (
  [TeamLine, 'top-[5050px]', 'left-0', '', '']
)

export const query = graphql`
query {
  allMdx(filter: {frontmatter: {templateKey: {eq: "workshop"}}}) {
    nodes {
      frontmatter {
        theworkshop {
          countdown {
            months
            days
            hours
          }
          intro {
            heading
            text
            imageAlt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          firstTagline
          description
          secondTagline
          videoSection {
            heading
            youtubeVideo
          }
          features {
            heading
            firstItem {
              text,
              image {
                publicURL
              }
            }
            secondItem {
              text,
              image {
                publicURL
              }
            }
            thirdItem {
              text,
              image {
                publicURL
              }
            }
            fourthItem {
              text,
              image {
                publicURL
              }
            }
            fifthItem {
              text,
              image {
                publicURL
              }
            }
          }
          trainer {
            trainerName
            trainerTitle
            subheading
            bio
            trainerImage {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
            bookImage {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
            bookImageAlt
            trainerImageAlt
          }
          infoCards {
            dates {
              firstDate
              secondDate
            }
            venue {
              place
              city
            }
            packages {
              firstPackage
              secondPackage
              thirdPackage
            }
            pricing {
              items {
                item
              }
              pricesLive {
                firstPrice
                secondPrice
              }
              pricesOnline {
                firstPrice
                secondPrice
              }
            }
          }
          firstSession {
            date
            timetable {
              time
              content
            }
          }
          secondSession {
            date
            timetable {
              time
              content
            }
          }
          thirdSession {
            date
            timetable {
              time
              content
            }
          }
          included {
            heading
            list {
              text
            }
          }
          thirdTagline
          faqs
          metaDescription
          metaTitle
        }
      }
    }
  }
}
` 
const IndexPage = ({ data }) => {
  const allData = data.allMdx.nodes[0].frontmatter.theworkshop
  const intro = data?.allMdx?.nodes[0].frontmatter.theworkshop.intro[0]
  const ButtonData = {
    text: 'Book Now',
    url: 'https://form.jotform.com/223561972967369',
  }

  return (
    <Layout line={LineData} lineTeam={TeamLineData} fullWidth>
      <Seo 
        title={allData?.metaTitle ? allData?.metaTitle : 'Learn to Write a Novel'}
        description={allData?.metaDescription ? allData?.metaDescription : 'If you want to know what makes a writer, what are the habits and behaviours you need to successfully write novels. Interested? Then this course is for you.'}
      />
      <Wrapper>
      <div className="mt-16"> 
        {intro && (
            <Story
              heading={intro.heading}
              subheading={intro.text}
              text=""
              button={ButtonData}
              image={intro.image}
              imageAlt={intro.imageAlt}
              reverse
              accent
              ext={true}
            />
          )
        }
        </div>
        {allData.firstTagline && (
          <AboutBox tagline={allData.firstTagline} position="center" size="large"/>
        )}
        {allData.description && (
          <div className="flex flex-col gap-y-10 my-16 lg:my-48 relative">
            <div className="
                flex flex-col
                w-full
                p-6 1xs:p-10 sm:p-16
                lg:px-36 
                lg:py-32
                bg-white
                rounded-lg
                "
            >
              <img 
                  src={DescBg}
                  alt=""
                  className="
                  w-[1064.09px] h-[800px]
                  -top-28
                  left-32
                  -z-50
                  absolute hidden xl:block"
              />
              <MarkdownContent 
                content={allData.description} 
                className="prose prose-base sm:prose-2xl max-w-none text-dark" 
              />
            </div>
          </div>
        )}
        </Wrapper>
        {allData.secondTagline && (
          <AboutBox tagline={allData.secondTagline} position="left"/>
        )}
        <Wrapper>
        {allData.videoSection && (
          <VideoSection 
            heading={allData.videoSection.heading} 
            youtubeVideo={allData.videoSection.youtubeVideo}
            button={ButtonData} 
          />
        )}
      </Wrapper>
        
      <Trainer trainer={allData.trainer}/>
      <Wrapper>
        {allData.infoCards && (
          <InfoCards infoCards={allData.infoCards}/>
        )}
      </Wrapper>

      <Wrapper>
        <div className="mx-auto max-w-[350px] my-8">
          <Button 
            ext={true}
            accent={true}
            text={ButtonData.text} 
            url={ButtonData.url} 
            className="mt-20 mx-auto max-w-[400px]"/>
        </div>
        
      </Wrapper>
     
      <Wrapper>
      
      </Wrapper>
      
      <div className="mx-auto text-center max-w-lg mb-16 mt-20">
      <Subheading text={'Keep me informed about future workshops'} />
      <Button text={'Subscribe to our newsletter'} url={'/#newsletter'} customClass="mx-auto lg:mx-auto xl:mx-auto" secondary/>
      </div>
    </Layout>
  )
}

export default IndexPage
